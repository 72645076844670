import React, { useEffect } from "react";
import ItemList from "../../../components/ItemList/ItemList";
import Button from "../../../controls/Button/Button";
import styles from "./Servers.module.css";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { selectServer } from "../../../redux/slices/serverSlice/serverSlice";
import {
  CreateServerArgs,
  createServer,
  fetchServers,
} from "../../../redux/thunks/serversService/serversThunk";
import ServerDetails from "./ServerDetails";
import Modal from "../../../controls/Modal/Modal";
import InputField from "../../../controls/InputField/inputField";
import * as Yup from "yup";
import { Formik } from "formik";
import DropdownField from "../../../controls/DropdownField/DropdownField";
import Loader from "../../../controls/Loader/Loader";

const softwareVersionOptions: { [key: string]: { label: string; value: string }[] } = {
  vanilla: [{ label: "1.20.4", value: "1.20.4" }, /* other vanilla versions */],
  forge: [{ label: "1.20.1", value: "1.20.1" }, { label: "1.19.2", value: "1.19.2" }, /* other forge versions */],
  // Add more software types and their versions as needed
};

export const serverSchema = Yup.object().shape({
  address: Yup.string().required("This is required"),
  software: Yup.string()
    .required("Software is required")
    .oneOf(["vanilla", "forge"], "Software must be either vanilla or forge"),
  version: Yup.string().required("Version is required"),
  ram: Yup.string().required("RAM is required"),
  name: Yup.string().required("Server name is required"),
});

const Servers: React.FC = () => {
  let dispatch = useDispatch<AppDispatch>();
  const [selected, setSelected] = React.useState<any>(null);
  const [items, setItems] = React.useState<any[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    disFetchServers();
  }, []);

  const disFetchServers = async () => {
    if (user) {
      setLoading(true);
      dispatch(fetchServers({ email: user.userAttributes.email })).then(
        (res) => {
          buildItems(res.payload.servers);
        }
      );
    }
  };

  const buildItems = (servers: any) => {
    let items: any[] = [];
    if (!servers) return;
    servers.forEach((server: any, index: number) => {
      items.push({
        index,
        primaryLabel: server.name,
        secondaryLabel: server.version + "  |  " + server.software,
        tertiaryLabel: (
          <div className={styles.serverListItem}>
            <span>Status: </span>
            <span
              style={{ color: server.status === "running" ? "green" : "red" }}
            >
              {server.status === "running" ? "Running" : "Stopped"}
            </span>
          </div>
        ),
        item: server,
      });
    });
    setItems(items);
    setLoading(false);
  };

  const handleCreateServer = ({
    name,
    address,
    software,
    version,
    ram,
  }: CreateServerArgs) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      dispatch(createServer({ name, address, software, version, ram }))
        .unwrap()
        .then((result) => {
          console.log("Server created successfully:", result);
          resolve(true);
        })
        .catch((error) => {
          console.error("Error creating server:", error);
          reject(new Error("An error has occurred"));
        });
    });
  };

  const updateSelected = (selected: any | null) => {
    dispatch(selectServer(selected.item.address));
    setSelected(selected);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShowCreateModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <ItemList
        title="Your Servers"
        loading={loading}
        items={items}
        bottomContent={
          <Button text="Add Server" onClick={handleShowCreateModal} />
        }
        updateSelected={updateSelected}
        selectedItem={selected}
      />
      <ServerDetails item={selected?.item} selected={selected} />
      <Formik
        initialValues={{
          address: "",
          software: "vanilla",
          version: "1.20.4",
          ram: 4,
          name: "",
        }}
        validationSchema={serverSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          setLoading(true);
          handleCreateServer({
            name: values.name,
            address: values.address,
            software: values.software,
            version: values.version,
            ram: values.ram,
          })
            .then(() => {
              setLoading(false);
              disFetchServers();
              handleClose();
              actions.resetForm();
            })
            .catch((e) => {
              actions.setFieldError("general", e.message);
            });
        }}
      >
        {(props) => (
          <Modal show={showModal} onClose={handleClose} size={400}>
            {loading ? (
              <div className={styles.loading}>
                <Loader />
              </div>
            ) : (
              <>
                <div className={styles.modalHeader}>
                  <h4 className={styles.modalTitle}>Create new server</h4>
                </div>
                <div className="modal-body">
                  <InputField
                    label="Server Name"
                    value={props.values.name}
                    error={props.touched.name && props.errors.name}
                    onChange={props.handleChange("name")}
                  />

                  <div style={{ display: "inline-flex", alignItems: "end" }}>
                    <InputField
                      label="Server Address"
                      value={props.values.address}
                      error={props.touched.address && props.errors.address}
                      onChange={props.handleChange("address")}
                      extraStyles={{ width: "130px" }}
                    />
                    <p
                      className={styles.domain}
                      style={{
                        marginBottom:
                          props.touched.address && props.errors.address
                            ? "40px"
                            : "20px",
                      }}
                    >
                      .servers.ggminecrafthosting.com
                    </p>
                  </div>

                  <DropdownField
                    label="Software"
                    value={props.values.software}
                    error={props.touched.software && props.errors.software}
                    onChange={props.handleChange("software")}
                    options={[
                      { label: "Vanilla", value: "vanilla" },
                      { label: "Forge", value: "forge" },
                    ]}
                  />

                  <DropdownField
                    label="Version"
                    value={props.values.version}
                    error={props.touched.version && props.errors.version}
                    onChange={props.handleChange("version")}
                    options={softwareVersionOptions[props.values.software] || []}
                  />

                  <InputField
                    type="number"
                    label="RAM Size"
                    value={props.values.ram}
                    error={props.touched.ram && props.errors.ram}
                    onChange={props.handleChange("ram")}
                  />

                  <div className={styles.generalError}>
                    <p>{(props.errors as any).general}</p>
                  </div>

                  <div className={styles.termsOfService}>
                    By creating a server you are agreeing to our terms of
                    service
                  </div>
                </div>
                <div className={styles.modalFooter}>
                  <Button text="Create Server" onClick={props.handleSubmit} />
                </div>
              </>
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default Servers;
