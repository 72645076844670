import React from "react";
import styles from "./FeatureItem.module.css";
import {
  ServerOutline,
  CashOutline,
  ShieldCheckmarkOutline,
  SpeedometerOutline,
  ConstructOutline,
  SettingsOutline,
} from "react-ionicons";

type FeatureItemProps = {
  icon: string;
  title: string;
  description: string;
};

const iconMapping: Record<string, React.ReactNode> = {
  server: <ServerOutline />,
  cash: <CashOutline />,
  security: <ShieldCheckmarkOutline />,
  performance: <SpeedometerOutline />,
  tools: <ConstructOutline />,
  settings: <SettingsOutline />,
};

const FeatureItem: React.FC<FeatureItemProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className={styles.featureItem}>
      <div className={styles.iconContainer}>{iconMapping[icon]}</div>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default FeatureItem;
