import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export interface ServersArgs {
  email: string;
  server?: string;
}
export interface CreateServerArgs {
  name: string;
  address: string;
  software: string;
  version: string;
  ram: number;
}

export const fetchServers = createAsyncThunk(
  "servers/fetchServers",
  async ({ email }: ServersArgs, thunkAPI) => {
    try {
      if (!email) {
        throw new Error("Email is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers`,
        method: "GET",
        params: {
          userId: email,
        },
      });

      return response.data;
    } catch (error: any) {
      console.log("error", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createServer = createAsyncThunk(
  "servers/createServer",
  async (
    { name, address, software, version, ram }: CreateServerArgs,
    thunkAPI
  ) => {
    try {
      if (!name || !address || !software || !version || !ram) {
        throw new Error("All fields are required");
      }

      const payload = {
        name,
        address: address + ".servers.ggminecrafthosting.com",
        software,
        version,
        ram,
      };

      const response = await api({
        url: `/servers-service/v1/servers`,
        method: "POST",
        data: payload,
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const startServer = createAsyncThunk(
  "servers/startServer",
  async ({ email, server }: ServersArgs, thunkAPI) => {
    try {
      if (!email) {
        throw new Error("Email is required");
      }
      if (!server) {
        throw new Error("Server is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${server}/start`,
        method: "POST",
      });

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const stopServer = createAsyncThunk(
  "servers/startServer",
  async ({ email, server }: ServersArgs, thunkAPI) => {
    try {
      if (!email) {
        throw new Error("Email is required");
      }
      if (!server) {
        throw new Error("Server is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${server}/stop`,
        method: "POST",
      });

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
