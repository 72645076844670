import React from "react";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const iconWidth = 40;

  const navigate = useNavigate();

  const handleTermsOfUse = () => {
    return () => {
      navigate("/terms-of-use");
    };
  };

  const handlePrivacyPolicy = () => {
    return () => {
      navigate("/privacy-policy");
    };
  };

  return (
    <div className={styles.footer}>
      <div className={styles.leftWrap}>
        <h6 onClick={handleTermsOfUse()}>Terms of Use</h6>
        <p>/</p>
        <h6 onClick={handlePrivacyPolicy()}>Privacy Policy</h6>
      </div>
      <div className={styles.centerWrap}>
        <h6>© 2024 ggMinecraftHosting</h6>
      </div>
      <div className={styles.rightWrap}>
        <div className={styles.iconWrap}>
          <a target="_blank" rel="noreferrer" href="https://www.x.com">
            <img
              src="/social/x.png"
              alt="X"
              width={iconWidth}
              height={iconWidth}
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.reddit.com">
            <img
              src="/social/reddit.png"
              alt="Reddit"
              width={iconWidth}
              height={iconWidth}
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.discord.com">
            <img
              src="/social/discord.png"
              alt="Discord"
              width={iconWidth}
              height={iconWidth}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
