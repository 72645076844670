import React, { FormEvent, useState } from "react";
import { signUp } from "aws-amplify/auth";
import styles from "../SignIn/SignIn.module.css";
import authstyles from "../../../styles/auth.module.css";
import InputField from "../../../controls/InputField/inputField";
import Button from "../../../controls/Button/Button";
import { useNavigate } from "react-router-dom";
import { signUpUser } from "../../../redux/thunks/authThunks";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleSignUp = async (event: FormEvent) => {
    event.preventDefault();
    try {
      dispatch(
        signUpUser({
          username: email,
          password,
          email,
        })
      ).then((res) => {
        let { isSignedIn, nextStep }: any = res.payload;
        if (!isSignedIn) {
          if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
            navigate("/verify", { state: { email, nextStep, meta: res.meta } });
          }
        } else {
          navigate("/dashboard");
        }
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleHomePress = () => {
    navigate("/");
  };

  return (
    <div className={authstyles.authContainer}>
      <div className={authstyles.authBackgroundWrap}></div>
      <div className={authstyles.formWrapper}>
        <form onSubmit={handleSignUp}>
          <div className={authstyles.logo} onClick={handleHomePress}>
            <p>ggMinecraftHosting</p>
          </div>
          <div className={authstyles.title}>
            Sign up to Start Your Minecraft Journey
          </div>
          <div className={authstyles.subtitleWrap}>
            <p>Already have an account?</p>
            <a href="/signin" className={authstyles.link}>
              Sign In
            </a>
          </div>
          <div className={authstyles.inputWrap}>
            <InputField
              type="name"
              label="Name"
              value={name}
              onChange={setName}
            />
            <InputField
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
            <InputField
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
            />
            {error && (
              <div className={authstyles.authError}>
                <span>{error}</span>
              </div>
            )}
          </div>
          <Button type="submit" text="Sign Up" className={authstyles.button} />
        </form>
      </div>
    </div>
  );
};

export default SignUp;
