import {
  addBannedPlayers,
  addOPS,
  addWhitelist,
  deleteBannedPlayers,
  deleteOPS,
  deleteWhitelist,
  fetchBannedPlayers,
  fetchOPS,
  fetchWhitelist,
} from "../../../thunks/serversService/playersThunk";

// ------------- Whitelist -------------

export const fetchWhitelistReducers = {
  [fetchWhitelist.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, whitelist: true };
    state.error = null;
  },
  [fetchWhitelist.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.whitelist = action.payload.whitelist;
      }
      return server;
    });
  },
  [fetchWhitelist.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.error = action.payload;
  },
};

export const addWhitelistReducers = {
  [addWhitelist.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, whitelist: true };
    state.error = null;
  },
  [addWhitelist.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.whitelist = action.payload.whitelist;
      }
      return server;
    });
  },
  [addWhitelist.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.error = action.payload;
  },
};

export const deleteWhitelistReducers = {
  [deleteWhitelist.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, whitelist: true };
    state.error = null;
  },
  [deleteWhitelist.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.whitelist = server.whitelist.filter(
          (user: any) => user.name !== action.payload.userName
        );
      }
      return server;
    });
  },
  [deleteWhitelist.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, whitelist: false };
    state.error = action.payload;
  },
};

// ------------- OPS -------------

export const fetchOpsReducers = {
  [fetchOPS.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, OPS: true };
    state.error = null;
  },
  [fetchOPS.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.OPS = action.payload.OPS;
      }
      return server;
    });
  },
  [fetchOPS.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.error = action.payload;
  },
};

export const addOpsReducers = {
  [addOPS.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, OPS: true };
    state.error = null;
  },
  [addOPS.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.OPS = action.payload.OPS;
      }
      return server;
    });
  },
  [addOPS.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.error = action.payload;
  },
};

export const deleteOpsReducers = {
  [deleteOPS.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, OPS: true };
    state.error = null;
  },
  [deleteOPS.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.OPS = server.OPS.filter(
          (user: any) => user.name !== action.payload.userName
        );
      }
      return server;
    });
  },
  [deleteOPS.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, OPS: false };
    state.error = action.payload;
  },
};

// ------------- Banned Players -------------

export const fetchBannedPlayersReducers = {
  [fetchBannedPlayers.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: true };
    state.error = null;
  },
  [fetchBannedPlayers.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.bannedPlayers = action.payload.bannedPlayers;
      }
      return server;
    });
  },
  [fetchBannedPlayers.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.error = action.payload;
  },
};

export const addBannedPlayersReducers = {
  [addBannedPlayers.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: true };
    state.error = null;
  },
  [addBannedPlayers.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.bannedPlayers = action.payload.bannedPlayers;
      }
      return server;
    });
  },
  [addBannedPlayers.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.error = action.payload;
  },
};

export const deleteBannedPlayersReducers = {
  [deleteBannedPlayers.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: true };
    state.error = null;
  },
  [deleteBannedPlayers.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        server.bannedPlayers = server.bannedPlayers.filter(
          (user: any) => user.name !== action.payload.userName
        );
      }
      return server;
    });
  },
  [deleteBannedPlayers.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, bannedPlayers: false };
    state.error = action.payload;
  },
};
