import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "../pages/shared/NotFound/NotFound";
import Layout from "../pages/shared/layout";
import HomePage from "../pages/home/Home/home";
import SignIn from "../pages/auth/SignIn/SignIn";
import SignUp from "../pages/auth/SignUp/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword";
import Servers from "../pages/main/Servers/Servers";
import Notifications from "../pages/main/Notifications/Notifications";
import DashboardLayout from "../pages/main/DashboardLayout/DashboardLayout";
import Payments from "../pages/main/Payments/Payments";
import Settings from "../pages/main/Settings/Settings";
import ProtectedRoute from "../navigation/ProtectedRoute";
import Verify from "../pages/auth/Verify/Verify";
import AuthRoute from "./AuthRoute";
import ContactUs from "../pages/home/ContactUs/ContactUs";

const AppNavigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route element={<AuthRoute />}>
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify" element={<Verify />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route index element={<Servers />} />
              <Route path="servers" element={<Servers />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="payments" element={<Payments />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppNavigator;
