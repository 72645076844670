import React, { useState } from "react";
import styles from "./PriceCalculator.module.css";

const PriceCalculator: React.FC = () => {
  const [ramSize, setRamSize] = useState(4); // Default 4GB
  const [dailyRunTime, setDailyRunTime] = useState(2); // Default 2 hours
  const creditCost = 0.03; // Cost per credit

  const calculateTotalCost = () => {
    const totalCredits = ramSize * dailyRunTime; // Total credits needed for the chosen RAM and time
    return (totalCredits * creditCost).toFixed(2); // Total cost calculation
  };

  return (
    <div className={styles.calculatorWrap}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderRamWrap}>
          <div className={styles.sliderRamHeader}>
            <label htmlFor="ram-size">Ram Size</label>
            <output>{ramSize}GB</output>
          </div>
          <input
            type="range"
            id="ram-size"
            name="ramSize"
            min="1"
            max="12"
            value={ramSize}
            onChange={(e) => setRamSize(Number(e.target.value))}
          />
        </div>
        <div className={styles.sliderRunTimeWrap}>
          <div className={styles.sliderRunTimeHeader}>
            <label htmlFor="daily-run-time">Daily run time</label>
            <output>{dailyRunTime}hrs</output>
          </div>
          <input
            type="range"
            id="daily-run-time"
            name="dailyRunTime"
            min="1"
            max="24"
            value={dailyRunTime}
            onChange={(e) => setDailyRunTime(Number(e.target.value))}
          />
        </div>
      </div>
      <div className={styles.estimatedTotalWrap}>
        <p className={styles.totalPriceHeader}>Estimated Total:</p>
        <div>
          <p className={styles.totalPriceAmount}>${calculateTotalCost()}</p>
          <p className={styles.totalPrice}>per day</p>
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
