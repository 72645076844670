import React from "react";
import styles from "./DropdownField.module.css";

type DropdownFieldProps = {
  value: any;
  label?: string;
  error?: string | boolean;
  onChange: (value: any) => void;
  options: { value: string; label: string }[];
};

const DropdownField: React.FC<DropdownFieldProps> = ({
  value,
  label,
  error,
  onChange,
  options,
}) => {
  return (
    <div>
      {label && <p className={styles.label}>{label}</p>}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.inputField}
        required
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default DropdownField;
