// UserList.tsx
import React from "react";
import styles from "./UserListItem.module.css";
import { TrashSharp } from "react-ionicons";
import { AddCircleSharp } from "react-ionicons";
import Loader from "../../../../../../controls/Loader/Loader";

type UserListItemProps = {
  isLoading: boolean;
  title: string;
  users: any[];
  extraFields?: string[];
  handleAddNewUser: (title: string) => void;
  handleDeleteUser: (title: string, userName: string) => void;
};

const UserListItem: React.FC<UserListItemProps> = ({
  isLoading,
  title,
  users,
  extraFields = [],
  handleAddNewUser,
  handleDeleteUser,
}) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.title}>
        <p>{title}</p>
        <div
          className={styles.titleAdd}
          onClick={() => handleAddNewUser(title)}
        >
          <AddCircleSharp
            color={"#345333"}
            title="add"
            height="30px"
            width="30px"
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {users.map((user) => (
            <div key={user.name} className={styles.user}>
              <div>
                <h6>{user.name}</h6>
                <p>UUID: {user.uuid ?? 0}</p>
                {extraFields.map((field) => (
                  <p key={field} className={styles.extraField}>
                    {field}: {user[field]}
                  </p>
                ))}
              </div>
              <div
                className={styles.userDelete}
                onClick={() => handleDeleteUser(title, user.name)}
              >
                <TrashSharp
                  color={"#CD4343"}
                  title="trash"
                  height="24px"
                  width="24px"
                />
              </div>
            </div>
          ))}
          {users.length === 0 && (
            <div className={styles.user}>
              <p>No players to be found...</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserListItem;
