import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import InputField from "../../../controls/InputField/inputField";
import Button from "../../../controls/Button/Button";
import authstyles from "../../../styles/auth.module.css";
import { useNavigate } from "react-router-dom";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleForgotPassword = async () => {
    try {
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleHomePress = () => {
    navigate("/");
  };

  return (
    <div className={authstyles.authContainer}>
      <div className={authstyles.authBackgroundWrap}></div>
      <div className={authstyles.formWrapper}>
        <form onSubmit={handleForgotPassword}>
          <div className={authstyles.logo} onClick={handleHomePress}>
            <p>ggMinecraftHosting</p>
          </div>
          <div className={authstyles.subtitleWrap}>
            <div className={authstyles.title}>Reset Your Password</div>
          </div>
          <div className={authstyles.inputWrap}>
            <InputField
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
          </div>
          <Button
            type="submit"
            text="Send Reset Instructions"
            className={authstyles.button}
          />
          <div className={styles.forgotPasswordWrap}>
            <a href="/signin" className={styles.forgotPassword}>
              Back to Sign In
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
