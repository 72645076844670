import { fetchAuthSession } from "@aws-amplify/auth";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface Config {
  baseURL: string;
}

const currentConfig: Config = {
  baseURL: "https://api.ggminecrafthosting.com",
};

let globalStore: any;

export const injectStore = (store: any) => {
  globalStore = store;
};

const direct: AxiosInstance = axios.create({
  baseURL: currentConfig.baseURL,
  timeout: 15000,
});

const getAPIHost = () => {
  return currentConfig.baseURL;
};

export const api = function (
  config: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
  return new Promise((resolve, reject) => {
    direct(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

direct?.interceptors.request.use(
  async (request: any) => {
    const startsWithAPI = request.url.startsWith(getAPIHost());
    const isRelativePath =
      request.url?.startsWith("/") || request.url?.startsWith("./");

    if (startsWithAPI || isRelativePath) {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};

      request.headers = request.headers || {};
      request.headers["Content-Type"] = "application/json";
      request.headers["Accept"] = "application/json";
      request.headers["Authorization"] = "Bearer " + idToken?.toString();
    }

    return Promise.resolve(request);
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

api.direct = direct;
api.getAPIHost = getAPIHost;

export default api;
