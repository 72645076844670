import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export interface FetchPlayersArgs {
  serverId: string;
}

export interface AddPlayersArgs {
  serverId: string;
  userName: string;
}

// ------------- Whitelist -------------

export const fetchWhitelist = createAsyncThunk(
  "servers/fetchWhitelist",
  async ({ serverId }: FetchPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/whitelist`,
        method: "GET",
      });

      return {
        serverId,
        whitelist: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addWhitelist = createAsyncThunk(
  "servers/addWhitelist",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/whitelist`,
        method: "PUT",
        data: {
          userName,
        },
      });

      return {
        serverId,
        whitelist: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteWhitelist = createAsyncThunk(
  "servers/deleteWhitelist",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      await api({
        url: `/servers-service/v1/servers/${serverId}/whitelist`,
        method: "DELETE",
        data: {
          userName,
        },
      });

      return {
        serverId,
        userName,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// ------------- OPS -------------

export const fetchOPS = createAsyncThunk(
  "servers/fetchOPS",
  async ({ serverId }: FetchPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/ops`,
        method: "GET",
      });

      return {
        serverId,
        OPS: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addOPS = createAsyncThunk(
  "servers/addOPS",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/ops`,
        method: "PUT",
        data: {
          userName,
        },
      });

      return {
        serverId,
        ops: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteOPS = createAsyncThunk(
  "servers/deleteOPS",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      await api({
        url: `/servers-service/v1/servers/${serverId}/ops`,
        method: "DELETE",
        data: {
          userName,
        },
      });

      return {
        serverId,
        userName,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// ------------- Banned Players -------------

export const fetchBannedPlayers = createAsyncThunk(
  "servers/fetchBannedPlayers",
  async ({ serverId }: FetchPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/bannedPlayers`,
        method: "GET",
      });

      return {
        serverId,
        bannedPlayers: response.data,
      };
    } catch (error: any) {
      console.log("error", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addBannedPlayers = createAsyncThunk(
  "servers/addBannedPlayers",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/bannedPlayers`,
        method: "PUT",
        data: {
          userName,
        },
      });

      return {
        serverId,
        bannedPlayers: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteBannedPlayers = createAsyncThunk(
  "servers/deleteBannedPlayers",
  async ({ serverId, userName }: AddPlayersArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      } else if (!userName) {
        throw new Error("Username is required");
      }

      await api({
        url: `/servers-service/v1/servers/${serverId}/bannedPlayers`,
        method: "DELETE",
        data: {
          userName,
        },
      });

      return {
        serverId,
        userName,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
