// src/store/authThunks.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  signIn,
  signOut,
  signUp,
  resetPassword,
  confirmSignUp,
  getCurrentUser,
  resendSignUpCode,
  fetchAuthSession,
  fetchUserAttributes,
} from "aws-amplify/auth";

interface SignInArgs {
  username: string;
  password: string;
}

interface RegisterArgs {
  username: string;
  password: string;
  email: string;
}

interface ResetPasswordArgs {
  email: string;
}

interface VerifyEmailArgs {
  email: string;
  code: string;
}

export const signInUser = createAsyncThunk(
  "auth/signIn",
  async ({ username, password }: SignInArgs, thunkAPI) => {
    try {
      const user = await signIn({ username, password });
      const userAttributes = await fetchUserAttributes();
      return {user, userAttributes};
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const refreshTokenUser = createAsyncThunk(
  "auth/refreshToken",
  async (_, thunkAPI) => {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const userAttributes = await fetchUserAttributes();
      return {
        username, userId, signInDetails, token: idToken?.toString(), userAttributes,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk for signing out
export const signOutUser = createAsyncThunk(
  "auth/signOut",
  async (_, thunkAPI) => {
    try {
      await signOut();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk for registering a new user
export const signUpUser = createAsyncThunk(
  "auth/register",
  async ({ username, password, email }: RegisterArgs, thunkAPI) => {
    try {
      const user = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true,
        },
      });
      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resendSignUpCodeUser = async ({ email }: { email: string }) => {
  try {
    await resendSignUpCode({ username: email });
  } catch (error: any) {
    return;
  }
};

export const resetPasswordUser = createAsyncThunk(
  "auth/resetPassword",
  async ({ email }: ResetPasswordArgs, thunkAPI) => {
    try {
      await resetPassword({ username: email });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmailUser = createAsyncThunk(
  "auth/verifyEmail",
  async ({ email, code }: VerifyEmailArgs, thunkAPI) => {
    try {
      let confirm = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      return confirm;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
