import { useState } from "react";
import styles from "./ContactUs.module.css";
import { Formik } from "formik";
import * as yup from "yup";
import { send } from "emailjs-com";
import { Helmet } from "react-helmet";

import NavMenu from "../../../components/NavMenu/NavMenu";
import InputField from "../../../controls/InputField/inputField";
import Button from "../../../controls/Button/Button";
import Footer from "../../../components/Footer/Footer";

interface IFormValues {
  Email: string;
  Message: string;
}

const ContactSchema = yup.object({
  Email: yup.string().required().email(),
  Message: yup.string().required().max(500),
});

const Contact = () => {
  const iconWidth = 50;
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (values: IFormValues, { setSubmitting }: any) => {
    let toSend = {
      from_name: "ggMinecraftHosting",
      to_name: "ggMinecraftHosting@gmail.com",
      message: values.Message,
      reply_to: values.Email,
    };
    setTimeout(() => {
      send("service_5nz2krb", "template_ohjnk9a", toSend, "Y9ciCf-4Bpf07VyNM")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubmitted(true);
        })
        .catch((err) => {
          console.log("FAILED...", err);
          setSubmitting(false);
        });
    }, 400);
  };

  return (
    <>
      <div className={styles.contactWrap}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Contact us! We’d love to support you"
          />
          <meta
            name="keywords"
            content="contact, support, feedback, message, email"
          />
          <title>Contact | ggMinecraftHosting</title>
        </Helmet>
        <NavMenu />
        <div className={styles.contact}>
          <div className={styles.leftContact}>
            <div className={styles.contactIntro}>
              <h3>Contact us! We’d love to support you</h3>
            </div>
            <p>
              If you have any problems with your program, or want to give some
              feedback, We'd love to hear it!
            </p>
            <div className={styles.iconWrap}>
              <a target="_blank" rel="noreferrer" href="https://www.x.com">
                <img
                  src="/social/x.png"
                  alt="X"
                  width={iconWidth}
                  height={iconWidth}
                />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.reddit.com">
                <img
                  src="/social/reddit.png"
                  alt="Reddit"
                  width={iconWidth}
                  height={iconWidth}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.discord.com"
              >
                <img
                  src="/social/discord.png"
                  alt="Discord"
                  width={iconWidth}
                  height={iconWidth}
                />
              </a>
            </div>
          </div>
          <div className={styles.rightContact}>
            {!submitted ? (
              <Formik
                initialValues={{
                  Email: "",
                  Message: "",
                }}
                validationSchema={ContactSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values, { setSubmitting });
                }}
              >
                {(props) => (
                  <div className={styles.contactForm}>
                    <form onSubmit={props.handleSubmit}>
                      <InputField
                        label="Email"
                        type="text"
                        onChange={props.handleChange("Email")}
                        value={props.values.Email}
                        error={props.errors.Email}
                      />

                      <InputField
                        label="Message"
                        type="text"
                        onChange={props.handleChange("Message")}
                        value={props.values.Message}
                        error={props.errors.Message}
                        multiline
                      />
                      <Button
                        text="Send Message"
                        style={{
                          marginTop: 10,
                          fontSize: 15,
                          width: "100%",
                          color: "white",
                        }}
                        type="submit"
                      />
                    </form>
                  </div>
                )}
              </Formik>
            ) : (
              <div className={styles.contactForm} style={{ marginTop: 50 }}>
                <p style={{ textAlign: "center" }}>
                  Success! Your message has been sent. We&#39;ll try get back to
                  you soon :)
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
