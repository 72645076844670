import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export interface FetchFilesArgs {
  serverId: string;
  path: string | null;
}

// ------------- Whitelist -------------

export const fetchFiles = createAsyncThunk(
  "servers/fetchFiles",
  async ({ serverId, path }: FetchFilesArgs, thunkAPI) => {
    try {
      if (!serverId) {
        throw new Error("Server Id is required");
      }

      const response = await api({
        url: `/servers-service/v1/servers/${serverId}/files`,
        method: "GET",
        params: {
          path,
        },
      });

      return {
        serverId,
        path,
        data: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
