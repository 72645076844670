import React, { FormEvent, useEffect, useState } from "react";
import styles from "./Verify.module.css";
import InputField from "../../../controls/InputField/inputField";
import Button from "../../../controls/Button/Button";
import authstyles from "../../../styles/auth.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  resendSignUpCodeUser,
  signInUser,
  verifyEmailUser,
} from "../../../redux/thunks/authThunks";

const Verify: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [resent, setResent] = useState<boolean>(false);

  useEffect(() => {
    if (!location.state) {
      navigate("/signin");
    }
  }, []);

  const handleVerify = async (event: FormEvent) => {
    event.preventDefault();
    let email = location.state.email;
    let password = location.state.meta.arg.password;
    try {
      // handle verify
      dispatch(verifyEmailUser({ email, code })).then((res) => {
        let { isSignUpComplete }: any = res.payload;
        if (isSignUpComplete) {
          dispatch(signInUser({ username: email, password: password })).then(
            (res) => {
              let { user }: any = res.payload;
              if (user.isSignedIn) {
                navigate("/dashboard");
              }
            }
          );
        }
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleResendCode = async () => {
    let email = location.state.email;
    try {
      resendSignUpCodeUser({ email }).then((res) => {
        setResent(true);
        setTimeout(() => {
          setResent(false);
        }, 30000);
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className={authstyles.authContainer}>
      <div className={authstyles.authBackgroundWrap}></div>
      <div className={authstyles.formWrapper}>
        <form onSubmit={handleVerify}>
          <div className={authstyles.logo}>
            <p>ggMinecraftHosting</p>
          </div>
          <div className={authstyles.subtitleWrap}>
            <div className={authstyles.title}>Verify Your Account</div>
          </div>
          <div className={authstyles.inputWrap}>
            <InputField
              type="text"
              label="Code"
              value={code}
              onChange={setCode}
            />
          </div>
          <Button type="submit" text="Verify" className={authstyles.button} />
          {!resent && (
            <div className={styles.forgotPasswordWrap}>
              <p onClick={handleResendCode} className={styles.forgotPassword}>
                Send a new code
              </p>
            </div>
          )}
          {resent && (
            <div className={styles.ResentWrap}>
              <p>Resent a new code! Check your email</p>
            </div>
          )}
          {error && (
            <div className={authstyles.authError}>
              <span>{error}</span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Verify;
