import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

export const fetchCredits = createAsyncThunk(
  "user/fetchCredits",
  async (_, thunkAPI) => {
    try {
      const response = await api({
        url: `/credits`,
        method: "GET",
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
