import Footer from "../../../components/Footer/Footer";
import NavMenu from "../../../components/NavMenu/NavMenu";
import Button from "../../../controls/Button/Button";
import styles from "./NotFound.module.css";

const NotFound = () => {
  return (
    <>
      <div className={styles.notfoundWrap}>
        <NavMenu />
        <div className={styles.aboutHeader}>
          <div className={styles.aboutHeaderContent}>
            <div>
              <img src="compass.gif" width={200} height={200} alt="notfound" />
              <h1>ooops... 404</h1>
              <p>page not found!</p>
            </div>
            <Button
              onClick={() => window.history.back()}
              text="Go Back"
              style={{ fontSize: 14 }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
