import React, { useEffect, useRef, useState } from "react";
import styles from "./Files.module.css";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectCurrentFilesWithPath } from "../../../../../redux/selectors/server";
import { fetchFiles } from "../../../../../redux/thunks/serversService/filesThunk";
import FolderList from "./FolderList/FolderList";
import FilesList from "./FilesListItem/FilesList";

const Files: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const path = useRef<string | null>(null);
  const [isNested, setIsNested] = useState<boolean>(false);

  const { isLoading, currentSelected } = useAppSelector(
    (state) => state.servers
  );

  const filesAndFoldersSelector = selectCurrentFilesWithPath(
    path.current ?? ""
  );

  const { files, folders } = useAppSelector(filesAndFoldersSelector);

  useEffect(() => {
    path.current = null;
    setIsNested(false);
    async function fetchData() {
      await dispatch(fetchFiles({ serverId: currentSelected, path: null }));
    }
    fetchData();
  }, [currentSelected]);

  const setPath = (folder: string, back: boolean) => {
    let newPath = path.current;

    if (back) {
      if (!newPath) return;
      const parts = newPath.split("/").filter(Boolean);
      parts.pop();
      newPath = parts.join("/") || "";
    } else {
      newPath = newPath ? `${newPath}/${folder}` : folder;
    }

    if (newPath && newPath.split("/").length > 0) {
      setIsNested(true);
    } else {
      setIsNested(false);
    }

    path.current = newPath;
    dispatch(fetchFiles({ serverId: currentSelected, path: newPath }));
  };

  return (
    <>
      <div className={styles.container}>
        <FolderList
          title="Folders"
          isLoading={isLoading?.files}
          folders={folders ?? []}
          nested={isNested}
          handleFolderClick={setPath}
        />
        <FilesList
          title="Files"
          isLoading={isLoading?.files}
          files={files ?? []}
        />
      </div>
    </>
  );
};

export default Files;
