import React, { FormEvent, useState } from "react";
import InputField from "../../../controls/InputField/inputField";
import Button from "../../../controls/Button/Button";
import authstyles from "../../../styles/auth.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInUser, signOutUser } from "../../../redux/thunks/authThunks";
import { AppDispatch } from "../../../redux/store";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();
    try {
      dispatch(signOutUser());
      dispatch(signInUser({ username: email, password })).then((res) => {
        let { user }: any = res.payload;
        if (!user?.isSignedIn) {
          setError("Incorrect username or password");
          handleNextStepAction(user?.nextStep?.signInStep, res);
        } else {
          navigate("/dashboard");
        }
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleNextStepAction = (nextStep: string, res: any) => {
    switch (nextStep) {
      case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
      case "CONFIRM_SIGN_UP":
        // Handle confirm sign in
        navigate("/verify", { state: { email, nextStep, meta: res.meta } });
        break;
      case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
        // Handle confirm sign in
        break;
      case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        // Handle confirm sign in
        break;
      case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        // Handle confirm sign in
        break;
      case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
        // Handle confirm sign in
        break;
      case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
        // Handle nwith confirm sign in
        break;
      case "RESET_PASSWORD":
        // Handle reset password
        break;
      default:
        // Handle unknown next step
        break;
    }
  };

  const handleHomePress = () => {
    navigate("/");
  };

  return (
    <div className={authstyles.authContainer}>
      <div className={authstyles.authBackgroundWrap}></div>
      <div className={authstyles.formWrapper}>
        <form onSubmit={handleSignIn}>
          <div className={authstyles.logo} onClick={handleHomePress}>
            <p>ggMinecraftHosting</p>
          </div>
          <div className={authstyles.title}>Sign in to dashboard</div>
          <div className={authstyles.subtitleWrap}>
            <p>Don’t have an account?</p>
            <a href="/signup" className={authstyles.link}>
              Sign Up
            </a>
          </div>
          <div className={authstyles.inputWrap}>
            <InputField
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
            />
            <InputField
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
            />
            <div className={authstyles.forgotPasswordWrap}>
              <a href="/forgot-password" className={authstyles.forgotPassword}>
                Forgot Password
              </a>
            </div>
            {error && (
              <div className={authstyles.authError}>
                <span>{error}</span>
              </div>
            )}
          </div>
          <Button type="submit" text="Sign In" className={authstyles.button} />
        </form>
      </div>
    </div>
  );
};

export default SignIn;
