import React from "react";
import SettingItem from "./SettingsItem/SettingItem";
import { useAppSelector } from "../../../../../redux/hooks";

const Settings: React.FC = () => {
  const servers = useAppSelector((state) => state.servers);

  console.log("servers", servers);
  return <>{/* <SettingItem title="Slots" status="max-players=21" /> */}</>;
};

export default Settings;
