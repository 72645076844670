import React from "react";
import ItemDetails from "../../../components/ItemDetails/ItemDetails";
import styles from "./Settings.module.css";

const UpdateDetails: React.FC<{ item: any; selected: any }> = ({
  item,
  selected,
}) => {
  if (!item) return null;

  return (
    <ItemDetails item={selected}>
      <>
        <div className={styles.serverCard}></div>
      </>
    </ItemDetails>
  );
};

export default UpdateDetails;
