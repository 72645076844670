import React from "react";
import ItemDetails from "../../../components/ItemDetails/ItemDetails";
import Button from "../../../controls/Button/Button";
import styles from "./Servers.module.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  startServer,
  stopServer,
} from "../../../redux/thunks/serversService/serversThunk";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Settings from "./TabMenu/Settings/Settings";
import Players from "./TabMenu/Players/Players";
import Files from "./TabMenu/Files/Files";

const ServerDetails: React.FC<{ item: any; selected: any }> = ({
  item,
  selected,
}) => {
  const [copyValue, setCopyValue] = React.useState("Click to copy");
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const handleIPCopy = () => {
    navigator.clipboard.writeText(item.address);
    setCopyValue("Copied!");
  };

  const handleStartServer = () => {
    dispatch(
      startServer({
        server: item.address,
        email: user.userAttributes.email,
      })
    );
  };

  const handleStopServer = () => {
    dispatch(
      stopServer({
        server: item.address,
        email: user.userAttributes.email,
      })
    );
  };

  if (!item) return null;

  return (
    <ItemDetails item={selected}>
      <>
        <div className={styles.serverCard}>
          <div className={styles.card + " " + styles.info}>
            <h1 className={styles.cardTitle}>Info</h1>
            <div style={{ flexGrow: 1 }}>
              <div className={styles.cardContent}>
                <h2>Software:</h2>
                <p>{item.software}</p>
              </div>
              <div className={styles.cardContent}>
                <h2>Version:</h2>
                <p>{item.version}</p>
              </div>
              <div className={styles.cardContent}>
                <h2>Memory:</h2>
                <p>{item.ram}GB</p>
              </div>
              <div className={styles.cardContent}>
                <h2>Status:</h2>
                <p>{item.status === "running" ? "Running" : "Stopped"}</p>
              </div>
            </div>
            <div className={styles.cardButtonWrap}>
              {item.status === "stopped" && (
                <Button
                  text="START"
                  className={styles.cardButton + " " + styles.cardButtonStart}
                  onClick={handleStartServer}
                />
              )}
              {item.status === "running" && (
                <Button
                  text="STOP"
                  className={styles.cardButton + " " + styles.cardButtonStop}
                  onClick={handleStopServer}
                />
              )}
            </div>
          </div>

          <div className={styles.leftWrap}>
            <div className={styles.card + " " + styles.serverDetails}>
              <h1 className={styles.cardTitle}>Server Details</h1>
              <div className={styles.serverDetailsWrap} onClick={handleIPCopy}>
                <p className={styles.serverIP}>{item.address}</p>
                <p className={styles.clickToCopy}>{copyValue}</p>
              </div>
            </div>

            <div className={styles.card + " " + styles.creditUsage}>
              <h1 className={styles.cardTitle}>Credit Usage</h1>
            </div>
          </div>
        </div>

        <div className={styles.card + " " + styles.serverTabs}>
          <Tabs selectedTabClassName={styles.tabSelected}>
            <TabList className={styles.tabList}>
              <Tab className={styles.tab}>Settings</Tab>
              <Tab className={styles.tab}>Players</Tab>
              <Tab className={styles.tab}>Files</Tab>
              <Tab className={styles.tab}>Backups</Tab>
            </TabList>

            <TabPanel>
              <Settings />
            </TabPanel>
            <TabPanel>
              <Players />
            </TabPanel>
            <TabPanel>
              <Files />
            </TabPanel>
          </Tabs>
        </div>
      </>
    </ItemDetails>
  );
};

export default ServerDetails;
