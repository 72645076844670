import { useState, useEffect, useRef } from "react";

export const useAutoFontSize = (text: string, maxWidth: number) => {
  const [fontSize, setFontSize] = useState<number>(16);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (textRef.current) {
        const currentWidth = textRef.current.offsetWidth;
        const currentFontSize = parseInt(
          window.getComputedStyle(textRef.current).fontSize,
          10
        );
        if (currentWidth > maxWidth && currentFontSize > 10) {
          setFontSize(currentFontSize - 1);
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, [text, maxWidth, fontSize]);

  return { fontSize, textRef };
};
