// src/store/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { ServerState } from "../../types/server";
import { resetAction } from "../../hooks";
import { serverReducers } from "./server/server";
import {
  addWhitelistReducers,
  deleteWhitelistReducers,
  fetchWhitelistReducers,
  fetchBannedPlayersReducers,
  addBannedPlayersReducers,
  deleteBannedPlayersReducers,
  deleteOpsReducers,
  addOpsReducers,
  fetchOpsReducers,
} from "./server/players";
import { fetchFilesReducers } from "./server/files";

const initialState: ServerState = {
  servers: [],
  currentSelected: "",
  isLoading: {
    general: false,
  },
  error: null,
};

const serversSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    selectServer: (state, action) => {
      state.currentSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Reset state
      .addCase(resetAction, () => initialState)
      // Fetch servers
      .addMatcher(
        (action) => action.type.startsWith("servers/fetchServers"),
        (state, action) => {
          serverReducers[action.type](state, action);
        }
      )
      //Whitelist
      .addMatcher(
        (action) => action.type.startsWith("servers/fetchWhitelist"),
        (state, action) => {
          fetchWhitelistReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/addWhitelist"),
        (state, action) => {
          addWhitelistReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/deleteWhitelist"),
        (state, action) => {
          deleteWhitelistReducers[action.type](state, action);
        }
      )
      //OPS
      .addMatcher(
        (action) => action.type.startsWith("servers/fetchOPS"),
        (state, action) => {
          fetchOpsReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/addOPS"),
        (state, action) => {
          addOpsReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/deleteOPS"),
        (state, action) => {
          deleteOpsReducers[action.type](state, action);
        }
      )
      //Banned Players
      .addMatcher(
        (action) => action.type.startsWith("servers/fetchBannedPlayers"),
        (state, action) => {
          fetchBannedPlayersReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/addBannedPlayers"),
        (state, action) => {
          addBannedPlayersReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/deleteBannedPlayers"),
        (state, action) => {
          deleteBannedPlayersReducers[action.type](state, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith("servers/fetchFiles"),
        (state, action) => {
          fetchFilesReducers[action.type](state, action);
        }
      );
  },
});

export const { selectServer } = serversSlice.actions;
export default serversSlice.reducer;
