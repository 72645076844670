import { fetchFiles } from "../../../thunks/serversService/filesThunk";

export const fetchFilesReducers = {
  [fetchFiles.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, files: true };
    state.error = null;
  },
  [fetchFiles.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, files: false };
    state.servers = state.servers.map((server: any) => {
      if (server.address === action.payload.serverId) {
        const data = action.payload.data;
        // Ensure the server has a filesystem object initialized
        server.filesystem = server.filesystem || {
          files: [],
          folders: [],
          loaded: false,
        };
        let currentLocation = server.filesystem;

        // Normalize the path to always work with a clean structure
        const normalizedPath = action.payload.path
          ? action.payload.path.split("/").filter(Boolean)
          : [];

        // Navigate through or initialize the filesystem structure based on the path
        normalizedPath.forEach((part: string) => {
          // If the current part does not exist, initialize it
          if (!currentLocation[part]) {
            currentLocation[part] = { files: [], folders: [], loaded: false };
          }
          currentLocation = currentLocation[part];
        });

        // Update the current location with the new data if it hasn't been loaded yet
        if (!currentLocation.loaded) {
          currentLocation.files = data.files;
          currentLocation.folders = data.folders;
          currentLocation.loaded = true; // Mark as loaded
        }
      }
      return server;
    });
  },
  [fetchFiles.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, files: false };
    state.error = action.payload;
  },
};
