// store/store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import serversReducer from "./slices/serverSlice/serverSlice";
import userReducer from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    servers: serversReducer,
    user: userReducer,
  },
});

// Define the RootState type based on the store's reducers
export type RootState = ReturnType<typeof store.getState>;

// Define the AppDispatch type based on the store's dispatch function
export type AppDispatch = typeof store.dispatch;

export default store;
