import React from "react";
import styles from "./ListItem.module.css";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as ExternalLink } from "../../icons/external-link.svg";

export interface ListItemProps {
  primaryLabel: string;
  secondaryLabel?: string;
  tertiaryLabel?: any;
  selected?: boolean;
  onSelect?: () => void;
  isLink?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  primaryLabel,
  secondaryLabel,
  tertiaryLabel,
  selected,
  onSelect,
  isLink = false,
}) => {
  return (
    <div
      className={`${styles.listItem} ${selected ? styles.selected : ""}`}
      onClick={onSelect}
    >
      <div className={styles.contentWrap}>
        <div className={styles.primaryLabel}>
          <span>{primaryLabel}</span>
        </div>
        {secondaryLabel && (
          <div className={styles.secondaryLabel}>
            <span>{secondaryLabel}</span>
          </div>
        )}
        {tertiaryLabel && (
          <div className={styles.tertiaryLabel}>{tertiaryLabel}</div>
        )}
      </div>
      <div className={styles.iconWrap}>
        {isLink ? <ExternalLink height={20} /> : <ChevronRight height={20} />}
      </div>
    </div>
  );
};

export default ListItem;
