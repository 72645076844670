import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { FileFolderStructure } from "../types/files";

const selectServers = (state: RootState) => state.servers.servers;
const selectCurrentSelectedServer = (state: RootState) =>
  state.servers.currentSelected;

const selectCurrentServer = createSelector(
  [selectServers, selectCurrentSelectedServer],
  (servers: any, currentSelected) =>
    servers.find((server: any) => server.address === currentSelected)
);

const selectCurrentFiles = createSelector(
  [selectServers, selectCurrentSelectedServer],
  (servers: any, currentSelected) =>
    servers.find((server: any) => server.address === currentSelected)?.files
);

const selectCurrentFilesWithPath = (path: string) =>
  createSelector(
    [selectServers, selectCurrentSelectedServer],
    (servers: any[], currentSelected: string): FileFolderStructure => {
      const server = servers.find(
        (server: any) => server.address === currentSelected
      );
      if (!server || !server.filesystem) {
        // If the server is not found or filesystem is not initialized, return empty structure
        return { files: [], folders: [] };
      }

      // Initialize with the filesystem root
      let currentLocation: FileFolderStructure = server.filesystem;

      // Handle root directory access directly
      if (path === null || path === "") {
        return {
          files: currentLocation.files || [],
          folders: currentLocation.folders || [],
        };
      }

      const parts = path.split("/").filter(Boolean); // Split and filter out empty strings from the path

      for (const part of parts) {
        // Ensure currentLocation[part] exists and is an object before attempting to navigate into it
        if (
          currentLocation[part] &&
          typeof currentLocation[part] === "object"
        ) {
          currentLocation = currentLocation[part];
        } else {
          // If a part of the path doesn't exist in the currentLocation structure, return empty structure
          return { files: [], folders: [] };
        }
      }

      // Return the files and folders from the final location in the path
      return {
        files: currentLocation.files || [],
        folders: currentLocation.folders || [],
      };
    }
  );

export {
  selectServers,
  selectCurrentSelectedServer,
  selectCurrentServer,
  selectCurrentFilesWithPath,
  selectCurrentFiles,
};
