import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { ReactComponent as CardIcon } from "../../icons/card.svg";
import { ReactComponent as CogSharpIcon } from "../../icons/cog-sharp.svg";
import { ReactComponent as NotificationsSharpIcon } from "../../icons/notifications-sharp.svg";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";
import { ReactComponent as ServerSharpIcon } from "../../icons/server-sharp.svg";
import { ReactComponent as ChevronDown } from "../../icons/chevron-down-outline.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as HelpCircle } from "../../icons/help-circle-outline.svg";
import { ReactComponent as UserCircle } from "../../icons/person-circle-outline.svg";
import { ReactComponent as ExternalLink } from "../../icons/external-link.svg";
import { useNavigate } from "react-router-dom";
import { signOutUser } from "../../redux/thunks/authThunks";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { useAutoFontSize } from "../../hooks/useAutoFontSize";
import api from "../../utils/api";

const menuItems = [
  { name: "Servers", icon: ServerSharpIcon, url: "/dashboard/servers" },
  {
    name: "Notifications",
    icon: NotificationsSharpIcon,
    url: "/dashboard/notifications",
  },
  {
    name: "Payments",
    icon: CardIcon,
    url: "https://www.stripe.com/dashboard/payments",
    isExternalLink: true,
  },
  { name: "Settings", icon: CogSharpIcon, url: "/dashboard/settings" },
];

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selected, setSelected] = useState<string>("Servers");
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const { credits } = useAppSelector((state) => state.user);
  const { user } = useAppSelector((state) => state.auth);
  const { fontSize, textRef } = useAutoFontSize(
    user?.userAttributes?.email,
    150
  );

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const generatePaymentURL = () => {
    api({
      url: "/stripe/v1/checkoutLink",
      method: "GET",
    }).then((res) => {
      window.open(res.data.message.paymentLink, "_blank");
    });
  };

  return (
    <div
      className={` ${styles.sidebar} ${
        isSidebarCollapsed ? styles.collapsed : ""
      }`}
    >
      <div className={styles.logo} onClick={toggleSidebar}>
        {!isSidebarCollapsed && <p>ggMinecraftHosting</p>}
        <ChevronRight width={18} height={18} />
      </div>
      <nav className={styles.navigation}>
        {menuItems.map((item) => (
          <button
            key={item.name}
            className={`${styles.navItem} ${
              selected === item.name ? styles.selected : ""
            }`}
            onClick={() => {
              if (item.isExternalLink) {
                if (item.name === "Payments") {
                  generatePaymentURL();
                  return;
                } else {
                  window.open(item.url, "_blank");
                  return;
                }
              }
              navigate(item.url);
              setSelected(item.name);
            }}
          >
            <item.icon className={styles.icon} />
            {!isSidebarCollapsed && (
              <span className={styles.navItemName}>{item.name}</span>
            )}
            {item.isExternalLink && !isSidebarCollapsed && (
              <span className={styles.externalLink}>
                <ExternalLink height={15} width={10} />
              </span>
            )}
          </button>
        ))}
      </nav>
      <a
        onClick={() =>
          window.open(
            "https://ggminecrafthosting.freshdesk.com/support/home",
            "_blank"
          )
        }
        className={styles.navItem}
      >
        <HelpCircle />
        {!isSidebarCollapsed && (
          <>
            <span className={styles.navItemName}>Help</span>
            <span className={styles.externalLink}>
              <ExternalLink height={15} width={10} />
            </span>
          </>
        )}
      </a>

      <div className={styles.footer}>
        <div className={styles.userInfo} onClick={toggleUserMenu}>
          {!isSidebarCollapsed && (
            <div className={styles.userInfoContent}>
              <span ref={textRef} style={{ fontSize: `${fontSize}px` }}>
                {user?.userAttributes?.email}
              </span>
              <span className={styles.userInfoContentCredits}>
                {credits} Credits
              </span>
            </div>
          )}
          {!isSidebarCollapsed ? (
            <ChevronDown
              className={`${styles.chevron} ${
                isUserMenuOpen ? styles.rotate : ""
              }`}
            />
          ) : (
            <UserCircle height={28} width={28} />
          )}
        </div>
        {isUserMenuOpen && (
          <div className={styles.userMenu}>
            <button
              className={styles.menuItem}
              onClick={() => {
                dispatch(signOutUser()).then(() => {
                  navigate("/signin");
                });
              }}
            >
              Sign Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
