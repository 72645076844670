import "./styles/globals.css";

import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import store from "./redux/store";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { injectStore } from "./utils/api";
import AppNavigator from "./navigation/AppNavigator";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

Amplify.configure(amplifyconfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

export default function App() {
  useEffect(() => {
    injectStore(store);
  }, []);

  return (
    <Provider store={store}>
      <AppNavigator />
    </Provider>
  );
}
