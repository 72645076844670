// src/store/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../types/auth";
import {
  signInUser,
  signOutUser,
  signUpUser,
  resetPasswordUser,
  verifyEmailUser,
  refreshTokenUser,
} from "../thunks/authThunks";

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handling signIn
    builder
      .addCase(signInUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Handling refreshToken
    builder
      .addCase(refreshTokenUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshTokenUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(refreshTokenUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Handling signOut
    builder.addCase(signOutUser.fulfilled, (state) => {
      state.user = null;
    });

    // Handling sign up
    builder
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Handling resetPassword
    builder
      .addCase(resetPasswordUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resetPasswordUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPasswordUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Handling verifyEmail
    builder
      .addCase(verifyEmailUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmailUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(verifyEmailUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
