import React from "react";
import styles from "./Modal.module.css"; // Importing the CSS module

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: number;
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  children,
  size = 500,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.modalBackdrop} onClick={onClose}>
      <div
        className={styles.modalContent}
        style={{ width: size }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
