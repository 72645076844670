import React from "react";
import ItemList from "../../../components/ItemList/ItemList";
import UpdateDetails from "./UpdateDetails";
import Preferences from "./Preferences";

const Settings: React.FC = () => {
  const [selected, setSelected] = React.useState<any>(null);

  const updateSelected = (selected: any | null) => {
    if (selected.index === 2) {
      window.open("https://ggminecrafthosting.com", "_blank");
      return;
    }
    setSelected(selected);
  };

  const settingItems: any = [
    { index: 0, primaryLabel: "Update Account Details" },
    { index: 1, primaryLabel: "Preferences" },
    { index: 2, primaryLabel: "Terms of Use", isLink: true },
  ];

  return (
    <>
      <ItemList
        title="Settings"
        items={settingItems}
        updateSelected={updateSelected}
        selectedItem={selected}
      />
      {selected?.index === 0 && (
        <UpdateDetails item={selected} selected={selected} />
      )}
      {selected?.index === 1 && (
        <Preferences item={selected} selected={selected} />
      )}
    </>
  );
};

export default Settings;
