import { fetchServers } from "../../../thunks/serversService/serversThunk";

export const serverReducers = {
  [fetchServers.pending.type]: (state: any) => {
    state.isLoading = { ...state.isLoading, general: true };
    state.error = null;
  },
  [fetchServers.fulfilled.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, general: false };
    state.servers = action.payload.servers;
  },
  [fetchServers.rejected.type]: (state: any, action: any) => {
    state.isLoading = { ...state.isLoading, general: false };
    state.error = action.payload;
  },
};
