// components/ProtectedRoute.tsx
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { refreshTokenUser } from "../redux/thunks/authThunks";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";

const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch<AppDispatch>();
  const [isSignedIn, setIsSignedIn] = React.useState<boolean>(false);

  useEffect(() => {
    async function checkUserAsync() {
      dispatch(refreshTokenUser()).then((res) => {
        let user: any = res.payload;
        if (!user?.username) {
          navigate("/signin");
        } else {
          setIsSignedIn(true);
        }
      });
    }
    checkUserAsync();
  }, []);

  return <>{isSignedIn && <Outlet />}</>;
};

export default ProtectedRoute;
