// UserList.tsx
import React from "react";
import styles from "./FolderList.module.css";
import Loader from "../../../../../../controls/Loader/Loader";
import { FolderOpenSharp, ArrowBackCircleSharp } from "react-ionicons";

type UserListItemProps = {
  isLoading: boolean;
  title: string;
  folders: any[];
  nested: boolean;
  handleFolderClick?: (folder: string, back: boolean) => void;
};

const FolderList: React.FC<UserListItemProps> = ({
  isLoading,
  title,
  folders,
  nested,
  handleFolderClick,
}) => {
  const folderItem = (folder: string) => {
    return (
      <div
        className={styles.folderItem}
        onClick={() => handleFolderClick && handleFolderClick(folder, false)}
      >
        <FolderOpenSharp
          color={"#404040"}
          title="folder"
          height="32px"
          width="32px"
        />
        <div>
          <h6>{folder}</h6>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.listItem}>
      <div className={styles.title}>
        <p>{title}</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.folderListWrap}>
            {nested && (
              <div
                className={styles.folderItem}
                onClick={() => handleFolderClick && handleFolderClick("", true)}
              >
                <ArrowBackCircleSharp
                  color={"#404040"}
                  title="folder"
                  height="32px"
                  width="32px"
                />
                <div>
                  <h6>Back</h6>
                </div>
              </div>
            )}
            {folders.map((folder) => (
              <div key={folder} className={styles.folder}>
                {folderItem(folder)}
              </div>
            ))}
          </div>
          {folders.length === 0 && (
            <div className={styles.folderItem}>
              <p>No files found...</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FolderList;
