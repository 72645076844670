import { createSlice } from "@reduxjs/toolkit";
import { fetchCredits } from "../thunks/userThunk";
import { UserState } from "../types/user";

const initialState: UserState = {
  credits: 0,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handling user credits
    builder
      .addCase(fetchCredits.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.credits = action.payload;
      })
      .addCase(fetchCredits.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
