// UserList.tsx
import React, { useEffect, useState } from "react";
import styles from "./Players.module.css";
import UserListItem from "./UserListItem/UserListItem";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectCurrentServer } from "../../../../../redux/selectors/server";
import {
  addBannedPlayers,
  addOPS,
  addWhitelist,
  deleteBannedPlayers,
  deleteOPS,
  deleteWhitelist,
  fetchBannedPlayers,
  fetchOPS,
  fetchWhitelist,
} from "../../../../../redux/thunks/serversService/playersThunk";
import Modal from "../../../../../controls/Modal/Modal";
import InputField from "../../../../../controls/InputField/inputField";
import Button from "../../../../../controls/Button/Button";

const Players: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [addValue, setAddValue] = useState("");
  const [title, setTitle] = useState("");

  const { isLoading, currentSelected } = useAppSelector(
    (state) => state.servers
  );
  const currentServer = useAppSelector(selectCurrentServer);

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchWhitelist({ serverId: currentSelected }));
      dispatch(fetchOPS({ serverId: currentSelected }));
      dispatch(fetchBannedPlayers({ serverId: currentSelected }));
    }
    fetchData();
  }, [currentSelected]);

  const handleAddNewUser = (title: string) => {
    setShowModal(true);
    setTitle(title);
  };

  const handleDeleteUser = (title: string, userName: string) => {
    if (title === "Whitelist") {
      dispatch(
        deleteWhitelist({ serverId: currentSelected, userName: userName })
      );
    } else if (title === "OPS") {
      dispatch(deleteOPS({ serverId: currentSelected, userName: userName }));
    } else if (title === "Banned Players") {
      dispatch(
        deleteBannedPlayers({ serverId: currentSelected, userName: userName })
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmitAdd = () => {
    if (title === "Whitelist") {
      dispatch(addWhitelist({ serverId: currentSelected, userName: addValue }));
    } else if (title === "OPS") {
      dispatch(addOPS({ serverId: currentSelected, userName: addValue }));
    } else if (title === "Banned Players") {
      dispatch(
        addBannedPlayers({ serverId: currentSelected, userName: addValue })
      );
    }
    setShowModal(false);
  };

  return (
    <>
      <div className={styles.container}>
        <UserListItem
          title="Whitelist"
          isLoading={isLoading?.whitelist}
          users={currentServer?.whitelist ?? []}
          handleAddNewUser={handleAddNewUser}
          handleDeleteUser={handleDeleteUser}
        />
        <UserListItem
          isLoading={isLoading?.OPS}
          title="OPS"
          users={currentServer?.OPS ?? []}
          extraFields={["level"]}
          handleAddNewUser={handleAddNewUser}
          handleDeleteUser={handleDeleteUser}
        />
        <UserListItem
          isLoading={isLoading?.bannedPlayers}
          title="Banned Players"
          users={currentServer?.bannedPlayers ?? []}
          extraFields={["reason", "expires", "source"]}
          handleAddNewUser={handleAddNewUser}
          handleDeleteUser={handleDeleteUser}
        />
      </div>
      <Modal show={showModal} onClose={handleClose} size={400}>
        <div className={styles.playersAddModal}>
          <h1>Add to {title}</h1>
          <InputField
            label="Username"
            value={addValue}
            onChange={setAddValue}
            type="text"
          />
          <Button text="Add" onClick={onSubmitAdd} />
        </div>
      </Modal>
    </>
  );
};

export default Players;
