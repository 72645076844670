import React, { useEffect, useState } from "react";
import { ItemListProps } from "./ItemListProps";
import styles from "./ItemList.module.css";
import ListItem from "../ListItem/ListItem";
import Loader from "../../controls/Loader/Loader";

const ItemList: React.FC<ItemListProps> = ({
  title,
  items,
  bottomContent,
  selectedItem,
  loading,
  updateSelected,
}) => {
  const handleUpdateSelected = (selected: any | null) => {
    if (selected !== null) {
      updateSelected(selected);
    }
  };

  useEffect(() => {
    if (items.length > 0 && !selectedItem) {
      updateSelected(items[0]);
    }
  }, [items]);

  return (
    <div className={styles.itemList}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.list}>
        {loading ? (
          <div className={styles.loading}>
            <Loader />
          </div>
        ) : (
          <>
            {items.length > 0 ? (
              <>
                {items.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      primaryLabel={item.primaryLabel}
                      secondaryLabel={item.secondaryLabel}
                      tertiaryLabel={item.tertiaryLabel}
                      selected={index === selectedItem?.index}
                      onSelect={() => handleUpdateSelected(item)}
                      isLink={item.isLink}
                    />
                  );
                })}
              </>
            ) : (
              <div className={styles.noItems}>
                <p>You have no items, let's make some soon!</p>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.bottomContent}>{bottomContent}</div>
    </div>
  );
};

export default ItemList;
