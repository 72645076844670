import React, { useState } from "react";
import styles from "./home.module.css";
import NavMenu from "../../../components/NavMenu/NavMenu";
import Button from "../../../controls/Button/Button";
import InfoSection from "../../../components/InfoSection/InfoSection";
import FeatureItem from "./FeatureItem/FeatureItem";
import PriceCalculator from "./PriceCalculator/PriceCalculator";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModShowcaseItem from "./ModShowcaseItem/ModShowcaseItem";
import { Helmet } from "react-helmet";
import Footer from "../../../components/Footer/Footer";

const App: React.FC = () => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const features = [
    {
      icon: "server",
      title: "Reliable Infrastructure",
      description:
        "Our servers are hosted on the best hardware in the industry, ensuring that your server is always up and running smoothly.",
    },
    {
      icon: "cash",
      title: "Cost-Effective Plans",
      description:
        "We offer a variety of pricing options to fit your budget, starting at just $3.00 a month for quality server hosting.",
    },
    {
      icon: "security",
      title: "Fortified Security",
      description:
        "Our servers are protected by the latest security measures to keep your server and your data safe from external threats.",
    },
    {
      icon: "performance",
      title: "Peak Performance",
      description:
        "Equipped with the latest hardware, our servers ensure that your gaming experience is lag-free and smoother than ever.",
    },
    {
      icon: "tools",
      title: "User-Friendly Management",
      description:
        "Manage your server with ease using our intuitive control panel, designed for efficiency and simplicity.",
    },
    {
      icon: "settings",
      title: "Fully Customizable",
      description:
        "Our servers offer full customization options, allowing you to install mods and plugins for an optimized gaming experience.",
    },
  ];

  const minecraftMods = [
    {
      name: "Spawn",
      imageUrl:
        "https://media.forgecdn.net/avatars/397/419/637598316981427421.png",
    },
    {
      name: "Minecraft Comes Alive",
      imageUrl:
        "https://media.forgecdn.net/avatars/5/928/635351436558080791.png",
    },
    {
      name: "Cave Dweller",
      imageUrl:
        "https://media.forgecdn.net/avatars/847/276/638246623337310128.png",
    },
    {
      name: "Twilight Forest",
      imageUrl:
        "https://media.forgecdn.net/avatars/14/212/635589178760357568.png",
    },
    {
      name: "Biomes O’Plenty",
      imageUrl:
        "https://media.forgecdn.net/avatars/477/194/637771671789296797.png",
    },
    {
      name: "Alex's Mobs",
      imageUrl:
        "https://media.forgecdn.net/avatars/543/777/637874731161865623.jpeg",
    },
    {
      name: "Mo' Creatures",
      imageUrl:
        "https://media.forgecdn.net/avatars/16/32/635638568329737894.png",
    },
    {
      name: "Immersive Portals",
      imageUrl:
        "https://media.forgecdn.net/avatars/243/192/637138303255280853.png",
    },
    {
      name: "Waystones",
      imageUrl:
        "https://media.forgecdn.net/avatars/42/418/636008504954894502.png",
    },
    {
      name: "RLCraft",
      imageUrl:
        "https://media.forgecdn.net/avatars/468/243/637751369169569212.png",
    },
    {
      name: "Pixelmon",
      imageUrl:
        "https://media.forgecdn.net/avatars/190/518/636856255591940035.png",
    },
    {
      name: "SkyFactory 4",
      imageUrl:
        "https://media.forgecdn.net/avatars/199/573/636907930795697123.png",
    },
    {
      name: "Good Ending",
      imageUrl:
        "https://media.forgecdn.net/avatars/623/814/638018335109733885.png",
    },
  ];

  const settings = {
    beforeChange: (current: number, next: number) => setActiveSlide(next),
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className={styles.homeWrap}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="ggMinecraftHosting is a premium Minecraft server hosting service that provides reliable, affordable, secure, and powerful servers for you and your friends to enjoy."
        />
        <meta
          name="keywords"
          content="minecraft, server, hosting, premium, reliable, affordable, secure, powerful"
        />
        <meta name="author" content="ggMinecraftHosting" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home | ggMinecraftHosting" />
        <meta
          property="og:description"
          content="ggMinecraftHosting is a premium Minecraft server hosting service that provides reliable, affordable, secure, and powerful servers for you and your friends to enjoy."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ggminecrafthosting.com" />
        <meta
          property="og:image"
          content="https://ggminecrafthosting.com/signin.png"
        />
        <meta property="og:site_name" content="ggMinecraftHosting" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ggMinecraftHosting" />
        <meta name="twitter:creator" content="@ggMinecraftHosting" />
        <meta
          name="twitter:title"
          content="ggMinecraftHosting - Premium Minecraft Server Hosting"
        />
        <meta
          name="twitter:description"
          content="ggMinecraftHosting is a premium Minecraft server hosting service that provides reliable, affordable, secure, and powerful servers for you and your friends to enjoy."
        />
        <meta
          name="twitter:image"
          content="https://ggminecrafthosting.com/signin.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="theme-color" content="#84D334" />
        <meta name="msapplication-TileColor" content="#84D334" />
        <title>Home | ggMinecraftHosting</title>
      </Helmet>
      <main>
        <section id="hero" className={styles.backgroundWrap}>
          <NavMenu />
          <div className={styles.heroWrap}>
            <p className={styles.subHeadline}>
              Reliable | Affordable | Secure | Powerful
            </p>
            <h1 className={styles.headline}>
              <span style={{ color: "#84D334" }}>Premium</span> Minecraft Server
              Hosting
            </h1>
            <p className={styles.pricingSubHeadline}>
              Set up your own server for as little as $3.00 a Month
            </p>
            <div style={{ width: 200 }}>
              <Button
                text="Try for Free"
                style={{ marginTop: 0 }}
                onClick={handleSignUp}
              />
            </div>
          </div>
          <div className={styles.aboutSection}>
            <InfoSection
              title="Why Choose ggMinecraftHosting?"
              content="ggMinecraftHosting is a premium Minecraft server hosting service that provides reliable, affordable, secure, and powerful servers for you and your friends to enjoy. Our servers are designed to be easy to set up and use, and we offer a variety of options"
            />
          </div>
        </section>

        <section id="about" className={styles.featureSection}>
          <div className={styles.topImage1}></div>
          <div className={styles.featuresWrap} id="features">
            <h1 className={styles.featuresHeader}>Our Server Features</h1>
            <div className={styles.features}>
              {features.map((feature) => (
                <FeatureItem
                  key={feature.title}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>
          <div className={styles.bottomImage1}></div>
        </section>

        <section id="modShowcase" className={styles.modShowcaseSection}>
          <div className={styles.modShowcaseWrap}>
            <h1 className={styles.modShowcaseHeader}>
              Plus access to all these mods + more
            </h1>

            <div className={styles.sliderContainer}>
              <Slider {...settings}>
                {minecraftMods.map((item, index) => (
                  <ModShowcaseItem
                    key={index}
                    name={item.name}
                    imageUrl={item.imageUrl}
                    active={index === activeSlide}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </section>

        <section id="pricing" className={styles.calculatorSection}>
          <div className={styles.topImage2}></div>
          <div className={styles.calculatorWrap}>
            <h1 className={styles.calculatorHeader}>
              All of that at a simple price ... try calculate it
            </h1>
            <PriceCalculator />
          </div>
          <div className={styles.bottomImage2}></div>
        </section>

        <section id="callToAction" className={styles.callToAction}>
          <div className={styles.callToActionWrap}>
            <h1 className={styles.callToActionHeader}>Ready to get started?</h1>
            <p className={styles.callToActionDesc}>
              So what are you waiting for? Set up your server today and start
              playing with your friends, or make new ones!
            </p>
            <div style={{ width: 200 }}>
              <Button
                text="Try for Free"
                style={{ marginTop: 0 }}
                onClick={handleSignUp}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default App;
