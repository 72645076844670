import React from "react";
import ItemList from "../../../components/ItemList/ItemList";
import ItemDetails from "../../../components/ItemDetails/ItemDetails";

const Notifications: React.FC = () => {
  const updateSelected = (selected: Number | null) => {
    console.log(selected);
  };

  return (
    <>
      <ItemList
        title="Notifications"
        items={[]}
        updateSelected={()=>{}} 
        selectedItem={null}
      />
      <ItemDetails item={{ primaryLabel: "Let’s play skywars" }}>
        <div>
          <div>
            <h1>Notification Details</h1>
            <button>Edit</button>
          </div>
          <div>
            <div>
              <span>Notification Name</span>
            </div>
            <div>
              <span>Let’s play skywars</span>
            </div>
          </div>
          <div>
            <div>
              <span>Notification Name</span>
            </div>
            <div>
              <span>Let’s play skywars</span>
            </div>
          </div>
          <div>
            <div>
              <span>Notification Name</span>
            </div>
            <div>
              <span>Let’s play skywars</span>
            </div>
          </div>
        </div>
      </ItemDetails>
    </>
  );
};

export default Notifications;
