import React, { useState } from "react";
import styles from "./NavMenu.module.css";
import Button from "../../controls/Button/Button";
import { useNavigate } from "react-router-dom";

const NavMenu: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleHome = () => {
    navigate("/");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleContactUs = (e: any) => {
    e.preventDefault();
    navigate("/contact-us");
  };

  const handleMenuChange = (id: string) => {
    navigate("/" + id);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoWrap}>
        <button onClick={toggleMenu} className={styles.menuToggle}>
          ☰
        </button>
        <div className={styles.logo} onClick={handleHome}>
          ggMinecraftHosting
        </div>
      </div>
      <nav className={`${styles.nav} ${isMenuOpen ? styles.show : ""}`}>
        <a
          href="#hero"
          className={styles.link}
          onClick={() => handleMenuChange("#hero")}
        >
          About
        </a>
        <a
          href="#pricing"
          className={styles.link}
          onClick={() => handleMenuChange("#pricing")}
        >
          Pricing
        </a>
        <a
          href="#features"
          className={styles.link}
          onClick={() => handleMenuChange("#features")}
        >
          Features
        </a>
        <a href="/contact-us" className={styles.link} onClick={handleContactUs}>
          Contact Us
        </a>
      </nav>
      <div className={styles.actionButtons}>
        <Button
          text="Sign In"
          variant="secondary"
          style={{
            width: "auto",
            marginTop: 0,
            padding: "12px 20px",
            marginRight: "10px",
            fontSize: "18px",
          }}
          onClick={handleSignIn}
        />
        <Button
          text="Sign Up"
          style={{
            width: "auto",
            marginTop: 0,
            padding: "12px 20px",
            fontSize: "18px",
          }}
          onClick={handleSignUp}
        />
      </div>
    </header>
  );
};

export default NavMenu;
