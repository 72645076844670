// UserList.tsx
import React from "react";
import styles from "./FilesList.module.css";
import { DownloadOutline } from "react-ionicons";
import Loader from "../../../../../../controls/Loader/Loader";

type UserListItemProps = {
  isLoading: boolean;
  title: string;
  files: any[];
};

const FilesList: React.FC<UserListItemProps> = ({
  isLoading,
  title,
  files,
}) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.title}>
        <p>{title}</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {files.map((file) => (
            <div key={file.key} className={styles.file}>
              <div>
                <h6>{file.key}</h6>
              </div>
              <div className={styles.fileDownload}>
                <a href={file.downloadUrl}>
                  <DownloadOutline
                    color={"#9FCB74"}
                    title="trash"
                    height="24px"
                    width="24px"
                  />
                </a>
              </div>
            </div>
          ))}
          {files.length === 0 && (
            <div className={styles.file}>
              <p>No files found...</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilesList;
