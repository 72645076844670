import React from "react";
import styles from "./InfoSection.module.css";

type InfoSectionProps = {
  title: string;
  content: string;
};

const InfoSection: React.FC<InfoSectionProps> = ({ title, content }) => {
  return (
    <section className={styles.infoSection}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.content}>{content}</p>
    </section>
  );
};

export default InfoSection;
