// components/ProtectedRoute.tsx
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { refreshTokenUser } from "../redux/thunks/authThunks";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";

const AuthRoute: React.FC = () => {
  let dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isSignedIn, setIsSignedIn] = React.useState<boolean>(true);

  useEffect(() => {
    async function checkUserAsync() {
      dispatch(refreshTokenUser()).then((res) => {
        let user: any = res.payload;
        if (user?.token) {
          navigate("/dashboard");
        } else {
          setIsSignedIn(false);
        }
      });
    }
    checkUserAsync();
  }, []);

  return <>{!isSignedIn && <Outlet />}</>;
};

export default AuthRoute;
