import React from "react";
import styles from "./ItemDetails.module.css";
import { ReactComponent as CogSharpIcon } from "../../icons/cog-sharp.svg";

interface ItemDetailsProps {
  item: any;
  children: React.ReactNode;
}

const ItemDetails: React.FC<ItemDetailsProps> = ({ item, children }) => {
  return (
    <section className={styles.itemDetails}>
      <div className={styles.itemDetailsHeader}>
        <h1>{item?.primaryLabel}</h1>
        <CogSharpIcon />
      </div>
      <div>{item && children}</div>
    </section>
  );
};

export default ItemDetails;
