import React, { CSSProperties } from "react";
import styles from "./InputField.module.css";

type InputFieldProps = {
  type?: string;
  value: any;
  placeholder?: string;
  label?: string;
  error?: string | boolean;
  onChange: (value: any) => void;
  multiline?: boolean;
  extraStyles?: CSSProperties;
};

const InputField: React.FC<InputFieldProps> = ({
  type = "text",
  value,
  placeholder,
  label,
  error,
  onChange,
  multiline,
  extraStyles,
}) => {
  // If multiline is true, render a textarea element
  if (multiline) {
    return (
      <div>
        <p className={styles.label}>{label}</p>
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          //Use both inputField and textArea classes
          className={`${styles.inputField} ${styles.inputFieldMultiLine}`}
          required
          style={extraStyles}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  } else {
    return (
      <div>
        <p className={styles.label}>{label}</p>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={styles.inputField}
          required
          style={extraStyles}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  }
};

export default InputField;
