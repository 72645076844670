import React from "react";
import styles from "./ModShowcaseItem.module.css"; // Make sure to create an FeatureItem.module.css

type ModShowcaseItemProps = {
  key: number;
  name: string;
  imageUrl: string;
  active: boolean;
};

const ModShowcaseItem: React.FC<ModShowcaseItemProps> = ({
  key,
  name,
  imageUrl,
  active,
}) => {
  const slideStyle = active
    ? `${styles.slide} ${styles.activeSlide}`
    : `${styles.slide} ${styles.inactiveSlide}`;

  return (
    <div className={slideStyle} key={key}>
      <img src={imageUrl} alt="Description" className={styles.slideImg} />
      <p className={styles.slideCaption}>{name}</p>
    </div>
  );
};

export default ModShowcaseItem;
